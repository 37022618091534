<template>
  <div>
    <div v-if="isMobile===false">

      <div v-if="languageSelect==='zh'" class="forget">
        <div class="header">
          <div class="centerWidth">
            <router-link to="/" class="login">
              <img loading="lazy" src="../assets/logo.png" alt="" />
            </router-link>
            <div class="name">忘记密码</div>
          </div>
        </div>
        <div class="center">
          <!-- <img loading="lazy" src="../assets/login.png" alt="" /> -->
          <div class="centerWidth">
            <div class="right">
              <div class="tabs">
                <div class="tabsItem">忘记密码</div>
              </div>
              <div class="tabContain">
                <el-input
                    placeholder="请输入邮箱/手机号"
                    v-model="form.account"
                ></el-input>
                <div class="flex">
                  <el-input
                      placeholder="请输入验证码"
                      v-model="form.code"
                  ></el-input>
                  <el-button
                      type="text"
                      :disabled="disabled"
                      class="setCode"
                      @click="getCode(form.phone)"
                  >{{ setCode }}</el-button
                  >
                </div>

                <el-input
                    placeholder="请输入密码"
                    v-model="form.password"
                    show-password
                    @input="changePasToop()"
                ></el-input>
                <p class="pwsRed">{{ toop }}</p>
                <el-input
                    placeholder="请再次输入密码"
                    v-model="form.requirePas"
                    show-password
                ></el-input>
                <div class="submit" @click="submit()">确认</div>
                <router-link to="/login" class="login">返回登录>></router-link>
              </div>
            </div>
          </div>
        </div>
        <SimpleBottom msg="Welcome to Your Vue.js App" />
      </div>

      <div v-if="languageSelect==='en'" class="forget">
        <div class="header">
          <div class="centerWidth">
            <router-link to="/" class="login">
              <img loading="lazy" src="../assets/logo.png" alt="" />
            </router-link>
            <div class="name">Forgot password</div>
          </div>
        </div>
        <div class="center">
          <!-- <img loading="lazy" src="../assets/login.png" alt="" /> -->
          <div class="centerWidth">
            <div class="right">
              <div class="tabs">
                <div class="tabsItem">Forgot password</div>
              </div>
              <div class="tabContain">
                <el-input
                    placeholder="Please enter your email/phone number"
                    v-model="form.account"
                ></el-input>
                <div class="flex">
                  <el-input
                      placeholder="Please enter the verification code"
                      v-model="form.code"
                  ></el-input>
                  <el-button
                      type="text"
                      :disabled="disabled"
                      class="setCode"
                      @click="getCodeEn(form.phone)"
                  >{{ setCodeEn }}</el-button
                  >
                </div>

                <el-input
                    placeholder="Please enter password"
                    v-model="form.password"
                    show-password
                    @input="changePasToopEn()"
                ></el-input>
                <p class="pwsRed">{{ toop }}</p>
                <el-input
                    placeholder="Please enter your password again"
                    v-model="form.requirePas"
                    show-password
                ></el-input>
                <div class="submit" @click="submit()">Confirm</div>
                <router-link to="/login" class="login">Back to login>></router-link>
              </div>
            </div>
          </div>
        </div>
        <SimpleBottom msg="Welcome to Your Vue.js App" />
      </div>
    </div>
    <div v-if="isMobile===true">

      <div v-if="languageSelect==='zh'" class="forget">
        <div class="header1">
          <div class="centerWidth">
            <router-link to="/" class="login">
              <img loading="lazy" src="../assets/logo.png" alt="" />
            </router-link>
            <div class="name">忘记密码</div>
          </div>
        </div>
        <div class="center1">
          <!-- <img loading="lazy" src="../assets/login.png" alt="" /> -->
          <div class="centerWidth">
            <div class="right">
              <div class="tabs">
                <div class="tabsItem">忘记密码</div>
              </div>
              <div class="tabContain">
                <el-input
                    placeholder="请输入邮箱/手机号"
                    v-model="form.account"
                ></el-input>
                <div class="flex">
                  <el-input
                      placeholder="请输入验证码"
                      v-model="form.code"
                  ></el-input>
                  <el-button
                      type="text"
                      :disabled="disabled"
                      class="setCode"
                      @click="getCode(form.phone)"
                  >{{ setCode }}</el-button
                  >
                </div>

                <el-input
                    placeholder="请输入密码"
                    v-model="form.password"
                    show-password
                    @input="changePasToop()"
                ></el-input>
                <p class="pwsRed">{{ toop }}</p>
                <el-input
                    placeholder="请再次输入密码"
                    v-model="form.requirePas"
                    show-password
                ></el-input>
                <div class="submit" @click="submit()">确认</div>
                <router-link to="/login" class="login">返回登录>></router-link>
              </div>
            </div>
          </div>
        </div>
        <SimpleBottom msg="Welcome to Your Vue.js App" />
      </div>

      <div v-if="languageSelect==='en'" class="forget">
        <div class="header1">
          <div class="centerWidth">
            <router-link to="/" class="login">
              <img loading="lazy" src="../assets/logo.png" alt="" />
            </router-link>
            <div class="name">Forgot password</div>
          </div>
        </div>
        <div class="center1">
          <!-- <img loading="lazy" src="../assets/login.png" alt="" /> -->
          <div class="centerWidth">
            <div class="right">
              <div class="tabs">
                <div class="tabsItem">Forgot password</div>
              </div>
              <div class="tabContain">
                <el-input
                    placeholder="Please enter your email/phone number"
                    v-model="form.account"
                ></el-input>
                <div class="flex">
                  <el-input
                      placeholder="Please enter the verification code"
                      v-model="form.code"
                  ></el-input>
                  <el-button
                      type="text"
                      :disabled="disabled"
                      class="setCode"
                      @click="getCodeEn(form.phone)"
                  >{{ setCodeEn }}</el-button
                  >
                </div>

                <el-input
                    placeholder="Please enter password"
                    v-model="form.password"
                    show-password
                    @input="changePasToopEn()"
                ></el-input>
                <p class="pwsRed">{{ toop }}</p>
                <el-input
                    placeholder="Please enter your password again"
                    v-model="form.requirePas"
                    show-password
                ></el-input>
                <div class="submit" @click="submit()">Confirm</div>
                <router-link to="/login" class="login">Back to login>></router-link>
              </div>
            </div>
          </div>
        </div>
        <SimpleBottom msg="Welcome to Your Vue.js App" />
      </div>
    </div>
    <LoginIn
        :dialogVisible="loginIn"
        @hideDialog="hideLoginIn"
        @register="showRegister"
        @forget="forget"
        @logsuccess="logsuccess"
    ></LoginIn>
    <RegisterIn
        :dialogVisible="registerIn"
        @hideDialog="hideregisterIn"
        @login="login"
    ></RegisterIn>
    <forgetIn
        :dialogVisible="forgetIn"
        @hideDialog="hidereforgetIn"
        @login="login"
    ></forgetIn>


  </div>
</template>

<script>
import SimpleBottom from "@/components/SimpleBottom.vue";
import { phoneCode, emailCode, forgetPassword } from "@/api/user";
import LoginIn from "@/views/userlog/LoginIn.vue";
import forgetIn from "@/views/userlog/forgetIn.vue";
import RegisterIn from "@/views/userlog/RegisterIn.vue";
export default {
  name: "forgetPassowrd",
  components: {RegisterIn, forgetIn, LoginIn, SimpleBottom },
  data() {
    return {
      isMobile:false,
      languageSelect:'',
      disabled: false,
      form: {
        account: "",
        code: "",
        password: "",
        requirePas: "",
      },
      setCode: "发送验证码",
      setCodeEn: "Verification code",
      toop: "",
      dialogVisibleInfo: false,
      registerIn:false,
      forgetIn:false,
      loginIn:false,
    };
  },
  computed: {},
  mounted() {
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }

  },
  methods: {
    hideDialog() {
      this.dialogVisible = false;
    },
    hideDialogInfo() {
      this.dialogVisibleInfo = false;
    },
    hideLoginIn(){
      this.loginIn=false

    },
    logsuccess(){
      this.loginIn=false
      this.userId= this.$store.state.userId
      window.location.reload();

    },
    showRegister(){
      this.loginIn=false
      this.registerIn=true
    },
    forget(){
      this.loginIn=false
      this.forgetIn=true
    },
    hideregisterIn(){
      this.registerIn=false
    },
    hidereforgetIn(){
      this.forgetIn=false
    },
    login(){
      this.loginIn=true
      this.registerIn=false
    },
    tabsChange() {
      this.isActive = this.isActive == 1 ? 2 : 1;
    },
    //获取验证码 并只验证手机号 是否正确
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      const reg1 = /^\d{6,13}$/;
      if (!this.form.account) {
        this.$message.error("请输入账号");
        return false;
      }
      if (!reg1.test(this.form.account) && !reg.test(this.form.account)) {
        this.$message.error("请输入正确的账号");
        return false;
      }
      if (reg.test(this.form.account)) {
        let data = {
          email: this.form.account,
          event: "resetpwd",
        }
        emailCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtn(); //验证码倒数60秒
        });
      } else {
        let data = {
          phone: this.form.account,
          event: "resetpwd",
        };
        phoneCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtn(); //验证码倒数60秒
        });
      }
    },
    getCodeEn() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      const reg1 = /^\d{6,13}$/;
      if (!this.form.account) {
        this.$message.error("Please enter your account number");
        return false;
      }
      if (!reg1.test(this.form.account) && !reg.test(this.form.account)) {
        this.$message.error("Please enter the correct account number");
        return false;
      }
      if (reg.test(this.form.account)) {
        let data = {
          email: this.form.account,
          event: "resetpwd",
        }
        emailCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtnEn(); //验证码倒数60秒
        });
      } else {
        let data = {
          phone: this.form.account,
          event: "resetpwd",
        };
        phoneCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtnEn(); //验证码倒数60秒
        });
      }
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCode = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCode = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    tackBtnEn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCodeEn = "Get verification code";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCodeEn ="Try again in "+ time + " seconds";
          time--;
        }
      }, 1000);
    },
    submit() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      const reg1 = /^\d{6,13}$/;
      if (!this.form.account) {
        this.$message.error("请输入账号");
        return false;
      }
      if (!reg1.test(this.form.account) && !reg.test(this.form.account)) {
        this.$message.error("请输入正确的账号");
        return false;
      }
      if (!this.form.code) {
        this.$message.error("请输入验证码");
        return false;
      }
      if (!this.form.password) {
        this.$message.error("请输入密码");
        return false;
      }
      if (!this.form.requirePas) {
        this.$message.error("请再次输入密码");
        return false;
      }
      if (this.form.password !== this.form.requirePas) {
        this.$message.error("两次密码不一致");
        return false;
      }
      let data = {
        username: this.form.account,
        code: this.form.code,
        password: this.form.password,
      };
      forgetPassword(data).then(() => {
        this.$message({
          message: "修改成功！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            this.loginIn=true
          },
        });
      });
    },
    submitEn() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      const reg1 = /^\d{6,13}$/;
      if (!this.form.account) {
        this.$message.error("Please enter your account number");
        return false;
      }
      if (!reg1.test(this.form.account) && !reg.test(this.form.account)) {
        this.$message.error("Please enter the correct account number");
        return false;
      }
      if (!this.form.code) {
        this.$message.error("Please enter the verification code");
        return false;
      }
      if (!this.form.password) {
        this.$message.error("Please enter password");
        return false;
      }
      if (!this.form.requirePas) {
        this.$message.error("Please enter your password again");
        return false;
      }
      if (this.form.password !== this.form.requirePas) {
        this.$message.error("Two passwords do not match");
        return false;
      }
      let data = {
        username: this.form.account,
        code: this.form.code,
        password: this.form.password,
      };
      forgetPassword(data).then(() => {
        this.$message({
          message: "Modified successfully！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            this.$router.push("/login");
          },
        });
      });
    },
    changePasToop() {
      var strongRegex = new RegExp(
        "(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,20}",
        "g"
      );
      var mediumRegex = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/;
      var enoughRegex = new RegExp("(?=.{6,}).*", "g");
      if (!enoughRegex.test(this.form.password)) {
        this.toop = "请输入6-20位密码";
      } else if (strongRegex.test(this.form.password)) {
        this.toop = "密码等级：强  当前密码安全性很高";
      } else if (mediumRegex.test(this.form.password)) {
        this.toop = "密码等级：中   当前密码安全性适中";
      } else {
        this.toop = "密码等级：弱   当前密码等级较低  账号被盗风险较大";
      }
    },
    changePasToopEn() {
      var strongRegex = new RegExp(
          "(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,20}",
          "g"
      );
      var mediumRegex = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/;
      var enoughRegex = new RegExp("(?=.{6,}).*", "g");
      if (!enoughRegex.test(this.form.password)) {
        this.toop = "Please enter a 6-20 digit password";
      } else if (strongRegex.test(this.form.password)) {
        this.toop = "Password level: Strong, The current password security is very high";
      } else if (mediumRegex.test(this.form.password)) {
        this.toop = "Password Level: Medium, The current password security is moderate";
      } else {
        this.toop = "Password level: Weak, The current password level is low. Accounts are more vulnerable to theft";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  background: #fff;
  height: 72px;
  display: flex;
  align-items: center;
  .centerWidth {
    display: flex;
    align-items: center;
    .login {
      // width: 168px;
      height: 40px;
    }
    .name {
      margin-left: 25px;
      padding-left: 30px;
      font-size: 20px;
      color: var(--main-color);
      height: 22px;
      line-height: 22px;
      border-left: 1px solid var(--main-color);
    }
  }
}
.center {
  height: calc(100vh - 46px - 72px);
  background: url(../assets/login.png) left top no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .centerWidth {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .right {
      width: 510px;
      height: fit-content;
      background: #fff;
      border-radius: 5px;
      padding: 42px 33px;

      .tabs {
        display: flex;
        align-items: center;
        justify-content: center;

        .tabsItem {
          height: 29px;
          line-height: 29px;
          font-size: 20px;
          color: var(--main-color);
          cursor: pointer;
        }
      }
      .tabContain {
        width: 100%;
        padding-top: 10px;
        > div {
          margin-top: 20px;
        }
        .el-input {
          width: 100%;
          height: 50px;
          line-height: 50px;
          border: 1px solid #d0d0d0;
          border-radius: 4px;
          // input {
          //   width: 100%;
          //   height: 100%;
          //   padding: 0 25px;
          //   box-sizing: border-box;
          //   display: block;
          //   font-size: 16px;
          //   color: #666666;
          //   // border-radius: 4px;
          // }
        }
        .el-input:place-holder {
          color: #bbbbbb;
        }
        .flex {
          display: flex;
          align-items: center;
          justify-content: space-between;

          ::v-deep {
            .setCode {
              width: 120px;
              height: 50px;
              // line-height: 50px;
              text-align: center;
              border-radius: 4px;
              background: var(--main-color);
              color: #fff;
              font-size: 14px;
              margin-left: 15px;
            }
          }

          .el-input {
            flex: 1;
          }
        }

        .submit {
          margin-top: 40px;
          height: 50px;
          line-height: 50px;
          border-radius: 4px;
          background: var(--main-color);
          color: #fff;
          text-align: center;
          cursor: pointer;
          font-size: 18px;
        }
        .login {
          margin: 0 auto;
          color: var(--main-color);
          font-size: 14px;
          display: block;
          margin-top: 17px;
          width: fit-content;
        }
      }
    }
  }
}
.header1 {
  background: #fff;
  height: 72px;
  display: flex;
  align-items: center;
  .centerWidth {
    display: flex;
    align-items: center;
    .login {
      width: 168px;
      height: auto;
    }
    .name {
      margin-left: 25px;
      padding-left: 30px;
      font-size: 20px;
      color: var(--main-color);
      height: 22px;
      line-height: 22px;
      border-left: 1px solid var(--main-color);
    }
  }
}
.center1 {
  height:75vh;
  background: url(../assets/login.png) left top no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;

  .centerWidth {

    display: flex;
    align-items: center;


    .right {
      width: 85%;
      margin: auto;
      align-items: center;
      text-align: center;
      height: auto;
      background: #fff;
      border-radius: 5px;
      padding: 12px 13px;

      .tabs {
        display: flex;
        align-items: center;
        justify-content: center;

        .tabsItem {
          margin: 0 10px;
          padding-bottom: 12px;
          font-size: 16px;
          color: var(--main-color);
          cursor: pointer;
        }
      }
      .tabContain {
        width: 100%;
        padding-top: 10px;
        > div {
          margin-top: 10px;
        }
        ::v-deep {
          .el-input__inner {
            line-height: 35px;
            height: 35px;
            font-size: 14px;
          }

          .el-input.is-active .el-input__inner,
          .el-input__inner:focus {
            border-color: #dcdfe6;
          }
        }
        .el-input {
          width: 100%;
          height: 40px;
          line-height: 40px;
          border: 1px solid #d0d0d0;
          border-radius: 4px;
          // input {
          //   width: 100%;
          //   height: 100%;
          //   padding: 0 25px;
          //   box-sizing: border-box;
          //   display: block;
          //   font-size: 16px;
          //   color: #666666;
          //   // border-radius: 4px;
          // }
        }
        .el-input:place-holder {
          color: #bbbbbb;
        }
        .flex {
          display: flex;
          align-items: center;
          justify-content: space-between;

          ::v-deep {
            .setCode {
              width: 75px;
              height: 40px;
              // line-height: 50px;
              text-align: center;
              border-radius: 4px;
              background: var(--main-color);
              color: #fff;
              font-size: 14px;
              margin-left: 5px;
            }
          }

          .el-input {
            flex: 1;
          }
        }

        .submit {
          margin-top: 20px;
          height: 30px;
          line-height: 30px;
          border-radius: 4px;
          background: var(--main-color);
          color: #fff;
          text-align: center;
          cursor: pointer;
          font-size: 14px;
        }
        .login {
          margin: 10px auto;
          color: var(--main-color);
          font-size: 14px;
          display: block;
          width: fit-content;
        }
      }
    }
  }
}
::v-deep {
  .el-input__inner {
    // height: 100%;
    line-height: 50px;
  }
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #dcdfe6;
  }
}
input {
  border: none;
  outline: none;
}
</style>
